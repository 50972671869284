<template>
  <div>
    <div class="header">
      <img class="header-img" src="../assets/home-logo.png" />
      <div class="header-right">
        <img
          class="search"
          @click="showSearch = !showSearch"
          :src="
            showSearch
              ? require('../assets/close.png')
              : require('../assets/search.png')
          "
        />
        <img
          class="menu"
          @click="showMenu = !showMenu"
          :src="
            showMenu
              ? require('../assets/close.png')
              : require('../assets/menu.png')
          "
        />
      </div>
    </div>
    <transition name="fade">
      <div class="search-wrapper" v-if="showSearch">
        <van-search
          placeholder="搜索..."
          background="#EEEEEE"
          v-model="searchValue"
          show-action
          @search="onSearch"
        >
          <template #action>
            <van-icon
              class="search-icon"
              name="search"
              size="20"
              @click="onSearch"
            />
          </template>
        </van-search>
      </div>
    </transition>
    <transition name="fade">
      <div class="menu-wrapper" v-if="showMenu">
        <van-collapse v-model="activeName" accordion class="van-collapse">
          <van-collapse-item title="走进金天" name="1">
            <div class="item-menu" @click="goInto(0)">集团简介</div>
            <div class="item-menu" @click="goInto(1)">集团要闻</div>
            <div class="item-menu" @click="goInto(2)">集团荣誉</div>
            <div class="item-menu" @click="goInto(3)">集团文化</div>
            <div class="item-menu" @click="goInto(4)">集团规划</div>
            <div class="item-menu" @click="goInto(5)">集团产业</div>
            <div class="item-menu" @click="goInto(6)">集团产品</div>
            <div class="item-menu" @click="goInto(7)">发展历程</div>
          </van-collapse-item>
          <van-collapse-item title="社会责任" name="2">
            <div class="item-menu" @click="goResponsibility(0)">公益动态</div>
            <div class="item-menu" @click="goResponsibility(1)">公益基金会</div>
            <div class="item-menu" @click="goResponsibility(2)">公益护苗</div>
            <div class="item-menu" @click="goResponsibility(3)">公益护盾</div>
            <div class="item-menu" @click="goResponsibility(4)">乡村振兴</div>
            <div class="item-menu" @click="goResponsibility(5)">
              社会公益赞助
            </div>
          </van-collapse-item>
          <van-collapse-item title="联系我们" name="3">
            <div @click="goContact()">联系我们</div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      searchValue: "",
      showSearch: false,
      showMenu: false,
      activeName: "1",
    };
  },
  methods: {
    onSearch() {
      if (this.searchValue == "") {
        return;
      }
      this.$router.push({
        name: "SearchPage",
        params: { value: this.searchValue },
      });
    },
    goInto(index) {
      this.$router.push({
        name: "into",
        params: { tabIndex: index },
      });
      this.showMenu = false;
    },
    goResponsibility(index) {
      this.$router.push({
        name: "responsibility",
        params: { tabIndex: index },
      });
      this.showMenu = false;
    },
    goContact() {
      this.$router.push({
        name: "contact",
      });
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss">
.header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  height: 3.5rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .header-img {
    height: 2.2rem;
    margin-left: 1rem;
  }
  .header-right {
    margin-right: 1rem;
    .search {
      width: 1.3rem;
      height: 1.3rem;
      margin-right: 1rem;
    }
    .menu {
      height: 1.3rem;
    }
  }
}
.search-wrapper {
  margin-top: 3.5rem;
  position: fixed;
  z-index: 998;
  width: 100%;
  .van-search {
    padding: 0.7rem 2.5rem 0.7rem 2.5rem;
  }
  .search-icon {
    display: flex;
    align-items: center;
    background-color: #de006f;
    color: #ffffff;
    padding: 0.2rem;
  }
}
.menu-wrapper {
  margin-top: 3.5rem;
  position: fixed;
  z-index: 998;
  right: 0;
  width: 10rem;
  .van-cell {
    background-color: #eeeeee;
    border-bottom: 1px solid #b8b8b8;
  }
  .van-collapse-item__content {
    background-color: #eeeeee;
  }
  .van-icon {
    color: #de006f !important;
  }
  .item-menu {
    line-height: 2rem;
    height: 2rem;
    padding: 0.1rem 0 0.1rem 1rem;
    border-bottom: 1px solid #b8b8b8;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>