<template>
  <div class="footer">
    <div class="qrcode">
      <div>
        <img src="../assets/qrcode1.jpg" />
        金天国际官方公众号
      </div>
      <!-- <div>
        <img src="../assets/qrcode2.jpg" />
        金天生养APP
      </div> -->
      <div>
        <img src="../assets/金天雪莲公众号.jpg" />
        金天国际雪莲养护公众号
      </div>
    </div>
    <van-divider :style="{ borderColor: '#D3D3D3', padding: '0 16px' }" />
    <div class="address">
      <div>金天国际医疗科技集团有限公司版权所有 <br>鲁ICP备16028713号-3</div>
      <div>地址：中国山东省烟台市福山区永达街986号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
      padding-top: 1rem;
      padding-bottom: 4rem;
      background-color: #eeeeee;
      .qrcode {
        display: flex;
        div {
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          font-size: 0.8rem;
          color: #7d7d7d;
          img {
            width: 6rem;
            height: 6rem;
            margin-bottom: 0.3rem;
          }
        }
      }
      .address{
        text-align: center;
        color: #333333;
        font-size: .8rem;
        line-height: 1.3rem;

      }
    }
</style>