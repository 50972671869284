import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Vant from 'vant';
import 'vant/lib/index.css';
import 'normalize.css'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import VueLazyload from 'vue-lazyload'
import axios from 'axios';

const loadimage = require('./assets1/loading.gif')
Vue.use(VueLazyload, {
  //参数配置 可不填
  
  // 懒加载默认加载图片
  loading: loadimage,
  // 加载失败后加载的图片
  error: 'xxx.png',
  preLoad: 1.3, // 预加载高度的比例
  attempt: 3 // 尝试加载次数
})
Vue.use(Viewer)

Vue.use(Vant);

const instance = axios.create({
  baseURL: '', // 设置为空字符串
})
// 将 axios 实例挂载到 Vue 的原型上
Vue.prototype.$http = instance
// 将api挂载到vue的原型上
import api from './utils/api'
Vue.prototype.$api = api;


Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
