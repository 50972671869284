import { get, post } from './http'; // 导入接口域名列表
import axios from 'axios';

const api = {
//1.判断来源
//2.动态替换地址

  // 集团要闻列表
  getNews() {
    return get('/jtywapi');
  },
  // 公益动态列表
  getWelfare() {
    return get('/gydtapi');
  },
  // 新闻内容接口,参数为新闻的主键ID
  getNewsDetail(params) {
    return get('/ncapi/' + params);
  },
  // 新闻搜索，参数值为汉字做 UrlEncode
  getSearch(params) {
    return get('/searchapi?keywords=' + encodeURI(params));
  },
}

export default api
