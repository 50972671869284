<template>
  <router-link :to="'/newsdetail/' + data.newsID" class="news-item">
    <div class="item-left">
      <div class="item-title">
        {{ data.title }}
      </div>
      <div class="item-date">
        {{
          this.dateFormat("YYYY-mm-dd", this.data.ReleaseTime.substring(6, 19))
        }}
        <label v-show="data.isTop">置顶</label>
      </div>
    </div>
    <div class="item-right">
      <van-image width="8rem" height="4rem" :src="imgUrl + data.yImgPath" />
    </div>
  </router-link>
</template>

<script>
import baseURL from "../utils/config";
export default {
  name: "news-item",
  props: ["data"],
  data() {
    return {
      // imgUrl: baseURL.imageUrl,
      imgUrl: 'https://www.jtgj.cc',

    };
  },
  methods: {
    dateFormat(fmt, date) {
      date = new Date(Number(date));
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
};
</script>

<style lang="scss" scoped>
.news-item {
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: dashed #eee 0.1rem;
  .item-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 0.5rem;
    margin-right: 1rem;
    .item-title {
      font-size: 1rem;
      font-weight: bold;
      color: #5b5b5b;
      line-height: 1.6rem;
      overflow: hidden;
      text-align: justify;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .item-date {
      margin-top: 0.3rem;
      color: #9b9b9b;
      font-size: 0.9rem;
      label {
        color: #de006f;
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
  .item-right {
    padding-right: 0.5rem;
  }
}
</style>