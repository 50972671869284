<template>
  <div class="container">
    <Header />
    <div class="content">
      <div class="swiper">
        <van-swipe :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in images" :key="index">
            <router-link :to="item.to">
              <van-image height="200" fit="fill" :src="item.image" />
            </router-link>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div style="height: 0.2rem; background-color: #eee"></div>
      <!-- <div class="activity">
        <div class="label">活动</div>
        <div class="text">免费领礼品活动...</div>
      </div> -->
      <div style="height: 0.3rem; background-color: #eee"></div>
      <div class="news">
        <div class="title">
          <div class="text">集团要闻</div>
          <router-link to="/newslist" class="more">更多&nbsp;></router-link>
        </div>
        <div v-for="(item, index) in newsData" :key="index">
          <news-item :data="item" />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import NewsItem from "@/components/NewsItem";
import axios from 'axios';

export default {
  data() {
    return {
      newsData: [],
      images: [
        {to:'/newsdetail/1784',image:require("../../assets/banner44.jpg")},
        {to:'',image:require("../../assets/banner55.jpg")},
        {to:'/newsdetail/1690',image:require("../../assets/banner66.jpg")},
        {to:'/newsdetail/1673',image:require("../../assets/banner0.jpg")},
      ],
    };
  },
  components: {
    Header,
    Footer,
    NewsItem,
  },
  created() {
    console.log(111)
  },
  mounted() {
    this.getNews();
  },
  methods: {
    getNews() {
      // this.$api.getNews().then((res) => {
      //   this.newsData = res.slice(0, 10);
      // });
      let a = window.location.href
      console.log(a)
      if(a == 'https://wap.jtgj.cc/#/') {
      // if(a == 'http://192.168.3.85:8080/#/') {
        axios.defaults.baseURL = 'https://www.jtgj.cc'
        axios.get('/jtywapi').then(response =>{
          this.newsData = response.data.slice(0, 10)
        }).catch(error =>{})
      }else if(a == 'http://wap.jtgj.cc/#/'){
        axios.defaults.baseURL = 'https://www.jtgj.cc'
        axios.get('/jtywapi').then(response =>{
          this.newsData = response.data.slice(0, 10)
        }).catch(error =>{})
      }
      
      // this.$http.get('https:/www.jtgj.cc/jtywapi')
      //   .then(response => {
      //   this.newsData = response.data.slice(0, 10)
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  .content {
    margin-top: 3.5rem;
    .swiper {
      background-color: #eee;
    }
    .activity {
      height: 3rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      .label {
        margin-left: 1rem;
        color: #de006f;
        font-size: 1rem;
        font-weight: bold;
        border: solid #de006f 0.1rem;
        border-radius: 0.5rem;
        padding: 0.2rem;
      }
      .text {
        color: #666666;
        font-size: 1.1rem;
        margin-left: 1rem;
      }
    }
    .news {
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0.5rem;
        align-items: center;
        height: 3rem;
        border-bottom: solid #eee 0.15rem;
        .text {
          font-size: 1.3rem;
          font-weight: bold;
        }
        .more {
          color: #666;
        }
      }
    }
  }
}
</style>